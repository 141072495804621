<template>
    <ProspectHeader />
    <component
        v-for="(widget, index) in availableWidgets"
        :key="index"
        :is="widget.component"
        v-bind="widget.props"
    />
    <div class="container-fluid content-type-19">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <p class="notice" v-html="$t('views.search.opt_out')"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProspectHeader from '@/components/elements/prospect/ProspectHeader';
import ProspectAiWidget from '@/components/elements/prospect/ProspectAiWidget';
import ProspectFirmologyAlt from '@/components/elements/prospect/ProspectFirmologyAlt';
import ProspectContactInfo from '@/components/elements/prospect/ProspectContactInfo';
import ProspectOpeningHoursAlt from '@/components/elements/prospect/ProspectOpeningHoursAlt';
import ProspectIndustries from '@/components/elements/prospect/ProspectIndustries';
import ProspectReviewsSummaryAlt from '@/components/elements/prospect/ProspectReviewsSummaryAlt';
import ProspectSiteSearch from '@/components/elements/prospect/ProspectSiteSearch';
import ProspectPeopleFreemium from '@/components/elements/prospect/ProspectPeopleFreemium';
import ProspectFamily from '@/components/elements/prospect/ProspectFamily';
import ProspectProperty from '@/components/elements/prospect/ProspectProperty';
import ProspectSameAddress from '@/components/elements/prospect/ProspectSameAddress';
import ProspectReviewsDetails from '@/components/elements/prospect/ProspectReviewsDetails';
import ProspectNews from '@/components/elements/prospect/ProspectNews';
import ProspectVacancies from '@/components/elements/prospect/ProspectVacancies';
import ProspectAppsDetails from '@/components/elements/prospect/ProspectAppsDetails';
import ProspectSiteRank from '@/components/elements/prospect/ProspectSiteRank';

export default {
    name: 'ProspectDetails',
    components: {
        ProspectHeader,
        ProspectAiWidget,
        ProspectFirmologyAlt,
        ProspectContactInfo,
        ProspectOpeningHoursAlt,
        ProspectIndustries,
        ProspectReviewsSummaryAlt,
        ProspectSiteSearch,
        ProspectPeopleFreemium,
        ProspectFamily,
        ProspectProperty,
        ProspectSameAddress,
        ProspectReviewsDetails,
        ProspectNews,
        ProspectVacancies,
        ProspectAppsDetails,
        ProspectSiteRank,
    },
    data() {
        return {
            availableWidgets: [
                {
                    id: 'ProspectAiWidget_summary',
                    component: 'ProspectAiWidget',
                    props: { slug: 'summary', defaultToggle: true, auto: false },
                },
                {
                    id: 'ProspectFirmologyAlt',
                    component: 'ProspectFirmologyAlt',
                    props: { defaultToggle: true },
                },
                {
                    id: 'ProspectContactInfo',
                    component: 'ProspectContactInfo',
                    props: { defaultToggle: true },
                },
                {
                    id: 'ProspectReviewsSummaryAlt',
                    component: 'ProspectReviewsSummaryAlt',
                    props: { defaultToggle: true },
                },
                {
                    id: 'ProspectOpeningHoursAlt',
                    component: 'ProspectOpeningHoursAlt',
                    props: { defaultToggle: false },
                },
                {
                    id: 'ProspectIndustries',
                    component: 'ProspectIndustries',
                    props: { defaultToggle: false },
                },
                {
                    id: 'ProspectAiWidget_summary_fundamentals',
                    component: 'ProspectAiWidget',
                    props: { slug: 'fundamentals', defaultToggle: true, auto: false },
                },
                {
                    id: 'ProspectSiteSearch',
                    component: 'ProspectSiteSearch',
                    props: { defaultToggle: true, auto: false },
                },
                {
                    id: 'ProspectPeopleFreemium',
                    component: 'ProspectPeopleFreemium',
                    props: { isAuthenticated: false, defaultToggle: true, auto: false },
                },
                {
                    id: 'ProspectFamily',
                    component: 'ProspectFamily',
                    props: { defaultToggle: true, auto: false },
                },
                {
                    id: 'ProspectProperty',
                    component: 'ProspectProperty',
                    props: { defaultToggle: true, auto: false },
                },
                {
                    id: 'ProspectSameAddress',
                    component: 'ProspectSameAddress',
                    props: { defaultToggle: true, auto: false },
                },
                {
                    id: 'ProspectReviewsDetails',
                    component: 'ProspectReviewsDetails',
                    props: { defaultToggle: true },
                },
                {
                    id: 'ProspectNews',
                    component: 'ProspectNews',
                    props: { defaultToggle: true, auto: false },
                },
                {
                    id: 'ProspectVacancies',
                    component: 'ProspectVacancies',
                    props: { defaultToggle: true, auto: false },
                },
                {
                    id: 'ProspectAppsDetails',
                    component: 'ProspectAppsDetails',
                    props: { defaultToggle: true },
                },
                {
                    id: 'ProspectSiteRank',
                    component: 'ProspectSiteRank',
                    props: { defaultToggle: true, auto: false },
                },
            ],
            // availableWidgets: [
            //     {
            //         id: 'ProspectAiWidget_summary',
            //         component: 'ProspectAiWidget',
            //         props: { slug: 'summary', defaultToggle: false, auto: false },
            //     },
            //     {
            //         id: 'ProspectAiWidget_summary_fundamentals',
            //         component: 'ProspectAiWidget',
            //         props: { slug: 'fundamentals', defaultToggle: false, auto: false },
            //     },
            //     {
            //         id: 'ProspectFirmologyAlt',
            //         component: 'ProspectFirmologyAlt',
            //         props: { defaultToggle: false },
            //     },
            //     {
            //         id: 'ProspectContactInfo',
            //         component: 'ProspectContactInfo',
            //         props: { defaultToggle: false },
            //     },
            //     {
            //         id: 'ProspectOpeningHoursAlt',
            //         component: 'ProspectOpeningHoursAlt',
            //         props: { defaultToggle: false },
            //     },
            //     {
            //         id: 'ProspectIndustries',
            //         component: 'ProspectIndustries',
            //         props: { defaultToggle: false },
            //     },
            //     {
            //         id: 'ProspectReviewsSummaryAlt',
            //         component: 'ProspectReviewsSummaryAlt',
            //         props: { defaultToggle: false },
            //     },
            //     {
            //         id: 'ProspectAiWidget_business_model',
            //         component: 'ProspectAiWidget',
            //         props: { slug: 'business_model', defaultToggle: false, auto: false },
            //     },
            //     {
            //         id: 'ProspectSiteSearch',
            //         component: 'ProspectSiteSearch',
            //         props: { defaultToggle: false, auto: false },
            //     },
            //     {
            //         id: 'ProspectPeopleFreemium',
            //         component: 'ProspectPeopleFreemium',
            //         props: { isAuthenticated: false, defaultToggle: false, auto: false },
            //     },
            //     {
            //         id: 'ProspectFamily',
            //         component: 'ProspectFamily',
            //         props: { defaultToggle: false, auto: false },
            //     },
            //     {
            //         id: 'ProspectProperty',
            //         component: 'ProspectProperty',
            //         props: { defaultToggle: false, auto: false },
            //     },
            //     {
            //         id: 'ProspectSameAddress',
            //         component: 'ProspectSameAddress',
            //         props: { defaultToggle: false, auto: false },
            //     },
            //     {
            //         id: 'ProspectReviewsDetails',
            //         component: 'ProspectReviewsDetails',
            //         props: { defaultToggle: false },
            //     },
            //     {
            //         id: 'ProspectNews',
            //         component: 'ProspectNews',
            //         props: { defaultToggle: false, auto: false },
            //     },
            //     {
            //         id: 'ProspectVacancies',
            //         component: 'ProspectVacancies',
            //         props: { defaultToggle: false, auto: false },
            //     },
            //     {
            //         id: 'ProspectAppsDetails',
            //         component: 'ProspectAppsDetails',
            //         props: { defaultToggle: false },
            //     },
            //     {
            //         id: 'ProspectSiteRank',
            //         component: 'ProspectSiteRank',
            //         props: { defaultToggle: false, auto: false },
            //     },
            // ],
        };
    }
};
</script>